@import './auth.scss';
@import './admin.scss';
@import './construction.scss';

.view-column {
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.view-column .ui.header .content {
  vertical-align: bottom !important;
}

.view-segment-group {
  margin-top: 1.7em !important;
}

.centered {
  text-align: center;
}

.form-top-button {
  display: inline;
}

.ui.disabled.segment {
  opacity: 1.0 !important;
}

.view-segment-group > .ui.disabled.segment {
  opacity: 0.8 !important;
  color: rgba(40, 40, 40, 0.8);
}
