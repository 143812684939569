@import "./base/base.scss";

.secondary-nav {
  display: none; // temporary
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: #fff;

  a {
    display: block;
    padding: 1rem;
    color: $gray5;
    text-decoration: none;
    font-weight: 700;
    transition: all ease-out 0.2s;

    &:hover {
      color: $gray7;
      background: $gray1;
    }
  }
}
