@import "./base/base.scss";

$primary-nav__hover-expand-anim: all ease-out 0.2s;

.aside-primary {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 1rem $gray3;
}

.ui.vertical.menu {
  width: 22rem;
}

.primary-nav {
  margin: 0;
  padding: 0;
  width: 22rem;
  height: 100%;
  list-style-type: none;
  white-space: nowrap;

  a {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 3rem;
    padding-left: 3rem;
    overflow: hidden;
    white-space: nowrap;
    max-width: 22rem;
    height: 2.1rem;
    color: $gray6;
    text-align: left;
    text-decoration: none;
    // font-size: 12px;
    font-weight: 700;
    transition: $primary-nav__hover-expand-anim;

    i {
      flex: 0 0 auto;
      width: 4rem;
      text-align: center;
    }

    .primary-nav__txt {
      flex: 1 1 auto;
      padding-right: 2rem;
      opacity: 1;
      transition: $primary-nav__hover-expand-anim;
    }
  }
}

.primary-nav li {
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.25rem;
    background: rgb(121, 121, 121);
    opacity: 0;
  }
}

.primary-nav li a:active,
.primary-nav li a:focus,
.primary-nav li a:hover {
  color: $gray9;
  background: $gray1;
}

.primary-nav li.active {
  color: $gray9;

  &::before {
    opacity: 1;
    z-index: 2;
  }

  a {
    color: $gray9;
    background: $gray0;
  }
}
