@import "./base/base.scss";

$pg-content-spinner-size: 8rem;

.pg-content {
  background: $gray1;
  overflow-x: hidden;
  overflow-y: visible;
}

.pg-content .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: calc(#{$pg-content-spinner-size} * -0.5);
  margin-left: calc(#{$pg-content-spinner-size} * -0.5);
  height: $pg-content-spinner-size;
}

h1 {
  font-size: 1.5rem;
}

.pg-content__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  padding: 1rem 2rem;
  background: #fff;

  button {
    flex: 0 0 auto;
    margin-right: 0.5rem;
    margin-left: -1rem;
    width: 3rem;
    height: 3rem;
    border: none;
    background: transparent;

    i {
      margin: 0;
    }
  }

  h1 {
    flex: 1 1 auto;
    margin-top: -0.25rem;
  }
}

.pg-content__body {
  padding: 1.5rem 2rem;
}
