.auth-container {
  display: flex;
  flex-flow: column nowrap;
  flex: auto;
  background-image: url('../images/bccsa_background.jpg');
  background-repeat: no-repeat;
  background-position: center;
}

.auth-grid {
  overflow-x: auto;
  margin: 0px !important;
}

.auth-column {
  margin-top: 60px;
  max-width: 400px;
  background-color: #ffffff;
}

.auth-column .ui.positive.button {
  background-color: #cd163f !important;
}

.auth-container.signup .auth-column {
  margin-top: 10px;
  max-width: 830px;
}

.auth-container.signup .type {
  float: right;
}

.forgotPasswordLink {
  font-size: 14px;
  bottom: 6px !important;
  right: 10px !important;
  position: absolute !important;
  color: gray;
}
.forgotPasswordLink:hover, .forgotPasswordLink:active, .forgotPasswordLink:focus {
  color: gray !important;
  outline: none;
}
