@import './components/spinner.scss';

.icon-button {
  padding: 0px !important;
}

.ui.header > .icon-button {
  display: inline-block;
  margin-left: 6px !important;
}

.hidden {
  display: none !important;
}

.edit-dialog .actions {
  float: right;
}

.monthly-table-dialog .actions {
  float: right;
}

.monthly-table-dialog .actions .button {
  margin-bottom: 1.5rem;
}

.ui.form .field > .currency-text {
  padding-top: 0.67em;
}

.number-input {
  text-align: right;
}

.currency-text {
  text-align: right;
}

.clickable {
  cursor: pointer
}

.segment-component {
  margin-bottom: 0px !important;
}

.ui.disabled.segment .currency-text {
  opacity: 1.0 !important;
  color: rgba(40, 40, 40, 1.0);
}

.dropdown .search {
  margin-left: 30px;
}

// 
.fake_input {
  background-color: white;
   margin: 0;
  outline: 0;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: 0.28571429rem;
}