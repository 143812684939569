@import './layout/primary-nav.scss';
@import './layout/secondary-nav.scss';
@import './layout/pg-content.scss';

html, body {
  min-height: 100%;
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  background: #ebebeb;
}

body * {
  box-sizing: border-box;
}

main {
  flex: 1 1 auto;
}

.app-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}

.app-header {
  position: relative;
  z-index: 2;
  padding: 1rem;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  align-items: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: 0 0.1rem 0.4rem $gray4;
}

.app-header .ui.positive.button {
  background-color: #cd163f !important;
}

.brand {
  display: inline !important;
  max-width: none !important;
  width: 18em !important;
}

.burger {
  margin-right: 0.4em !important;
  vertical-align: 0.1em !important;
}

.app-body {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: inherit;
  height: 100%;
}

.app-body > div {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  height: 100%;
}

.app-body .pushable, .pusher {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
}

.app-body .sidebar {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  width: 22rem !important;
  overflow-x: hidden;
  background: #fff;
}

.app-footer {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  margin: 0px !important;
  color: #ffffff;
  background-color: #9e3439 !important;
  font-size: 0.85rem;
}

.main-body {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
}
